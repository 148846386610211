export const state = {
  session: {
    
  }
};

export const mutations = {
  SET_SESSION_DATA(state, data) {
    state.session = data

    if (data) {
      localStorage.setItem('session', JSON.stringify(data))
      sessionStorage.setItem('session', JSON.stringify(data))
    }
  },

  CLEAR_SESSION_DATA(state) {
    state.session = {}

    sessionStorage.removeItem('session')
    localStorage.removeItem('session')
  },
}

export const actions = {
  setSessionData({ commit }, session_data) {
    commit('SET_SESSION_DATA', session_data)
  },
  logoutUser({ commit }) {
    commit('CLEAR_SESSION_DATA')
  },
}

export const getters = {
  sessionData: (state) => () =>{ return state.session },
  userRole: (state) => () => { return state.session.role },
}